<template>
  <div class="auth-main viewContainer" >
    <v-container class="auth-container pa-4">
      <div>
        <v-tabs
          :color="($store.state.app.dark) ? 'white' : 'black'"
          class="content-tabs mt-5"
          background-color="transparent"
          fixed-tabs
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab :to="{ name: 'login' }" class="no-caps" :ripple="false">
            {{ $t('log_in') }}
            <!-- <v-icon size="24">mdi-account</v-icon> -->
          </v-tab>
          <v-tab :to="{ name: 'password.email' }" class="no-caps" :ripple="false">
            {{ $t('forgot_password') }}
            <!-- <v-icon size="24">mdi-key-variant</v-icon> -->
          </v-tab>
        </v-tabs>
        <v-card rounded class="content-tabs-card mt-5 px-5 py-5 rounded">
          <ValidationObserver ref="form1" v-slot="{ invalid }">
            <v-form 
              :model="form1" 
              @submit.prevent="submit('form1')"
              autocomplete="off"
              method="post"
            >
              <h1 class="text-display-2 font-weight-medium mb-5">{{ $t('sign_in') }}</h1>
              <p class="mb-5 text-subtitle-1 text--secondary">{{ $t('sign_in_head') }} <router-link :to="{name: 'register'}">{{ $t('sign_up') }}</router-link></p>
              <v-alert
                :value="form1.hasError"
                type="error"
                text
                class="mb-4"
              >
                <span v-html="$t('login_not_recognized')"/>
              </v-alert>
              <v-alert
                :value="successRegistrationRedirect"
                type="success"
                text
                class="mb-4"
              >
                {{ $t('successfully_registered_info') }}
              </v-alert>
              <v-alert
                :value="successResetUpdateRedirect"
                type="success"
                text
                class="mb-4"
              >
                {{ $t('password_reset_success') }}
              </v-alert>
              <x-text-field 
                type="email"
                v-model="form1.email"
                ref="form1.email"
                id="form1.email"
                :label="$t('email')"
                :placeholder="$t('email')"
                rules="required|email"
              />
              <x-password
                v-model="form1.password"
                ref="form1.password"
                id="form1.password"
                :label="$t('password')"
                :placeholder="$t('password')"
                rules="required|min:8|max:24"
              />
              <x-checkbox
                v-model="form1.rememberMe"
                ref="form1.rememberMe"
                id="form1.rememberMe"
                :label="$t('remember_me')"
              />
              <v-btn color="primary" class="no-caps mt-3" rounded x-large depressed block :loading="form1.loading" :disabled="form1.loading || invalid" type="submit">{{ $t('log_in') }} <v-icon right>mdi-arrow-right</v-icon></v-btn>
            </v-form>
          </ValidationObserver>
        </v-card>

      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    tab: 'tab-0',
    topBgImg: 'about:blank',
    topBgImgHeight: null,
    topBgImgDefaultHeight: 780,
    topBgImgAspectRation: 1.7778,
    topBgImgs: [],
    successRegistrationRedirect: false,
    successResetUpdateRedirect: false,
    all_account: [],
    loading_switch: false,
    index_switch: -1,
    form1: {
      loading: false,
      email: '',
      password: '',
      rememberMe: true,
      hasError: false,
    },
    isInit: false,
    isSignIn: false,
    loading_google: false
  }),
  created () {
    this.successRegistrationRedirect = this.$route.params.successRegistrationRedirect || false
    this.form1.email = this.$route.params.email || null
    this.successResetUpdateRedirect = this.$route.params.successResetUpdateRedirect || false
    if(this.$root && this.$root.$refs && this.$root.$refs.master){
      this.$root.$refs.master.has_currencies = false
    }
    
    // let that = this;
    // let checkGauthLoad = setInterval(function () {
    //   that.isInit = that.$gAuth.isInit;
    //   that.isSignIn = that.$gAuth.isAuthorized;
    //   if (that.isInit) clearInterval(checkGauthLoad);
    // }, 1000);
  },
  methods: {
    async submit (formName) {
      // Get the redirect object
      let redirect = this.$auth.redirect()

      // Reset form validation
      this.$refs[formName].reset()

      // Form defaults
      let form = this[formName]
      form.hasError = false
      form.loading = true

      this.$auth.login({
        rememberMe: form.rememberMe,
        fetchUser: true,
        data: {
          locale: this.$i18n.locale,
          email: form.email,
          password: form.password,
          remember: form.rememberMe
        },
        success () {

          let redirectTo

          if (parseInt(this.$auth.user().role) == 1) redirectTo = 'admin.backlink-history'
          if (parseInt(this.$auth.user().role) == 2) redirectTo = 'tracking-keyword'
          if (parseInt(this.$auth.user().role) == 3) redirectTo = 'sales.billing'
          
          // Redirect
          this.$router.push({name: redirectTo})
          this.$root.$refs.master.getCredit()
          this.$root.$refs.master.getRateCurrency()
        },
        error: function (error) {
          window.scrollTo(0,0)
          let errors = error.response.data.errors || []

          for (let field in errors) {
            this.$refs[formName + '.' + field].applyResult({
              errors: errors[field],
              valid: false,
              failedRules: {}
            })
          }

          if (errors.length === 0) {
            form.hasError = true
          }

          form.loading = false
        }
      })
    }
  },
  watch: {
    tab: function (val) {
      if (window.innerWidth > 760) {
        this.topBgImg = this.topBgImgs[0]
        this.topBgImgHeight = this.topBgImgDefaultHeight
        this.topBgImgAspectRation = 1.7778
      }
    }
  }
};
</script>
<style>
.custom-text-field{
  margin: 0 !important;
  width: 100%;
  max-width: 100% !important;
}
</style>